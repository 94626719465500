<template>
  <div>
    <div class="page-header">
      <b-row>
        <b-col class="filters ml-2" lg="2">
          <div class="filter-column">
            <v-select
                @input="filterRegion"
                placeholder="Ընտրեք մարզը"
                id="vue-select-region"
                :reduce="prefix => prefix.id"
                v-model="filters.region"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="regions"
                class="selectorFilter"
            />
          </div>
          <div class="filter-column">
            <v-select
                @input="filterCounty"
                placeholder="Ընտրեք համայնքը"
                id="vue-select-county"
                :reduce="prefix => prefix.id"
                v-model="filters.county"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="counties"
                class="selectorFilter"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
    >
      <template #cell(_id)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(actions)="data">
        <b-button class="w-100" @click="getInfo(data.item._id)" variant="success">Վերլուծություն</b-button>
      </template>
      <template #cell(address)="data">
        {{ addressName(data.item) }}
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
          :label="'Գտնվել է ' + totalRows + ' տող'"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
      >
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <b-modal
        id="modal-info"
        :no-close-on-backdrop="true"
        centered
        size="md"
        class="modal-responsive"
        title="Վերլուծություն"
        :is-valid="false"
    >

      <b-form v-if="info">
        <b-form-group>
          <div class="infos_title">
            {{ info.company.name }}
          </div>
          <div class="infos">
            <div class="info">
              <div class="info-column">
                <span>ԱՏՄ</span>
                <div v-b-tooltip.hover.top="'Անսարք ճանաչված տրանսպորտային միջոցների քանակը'"  class="icon">
                  <fa-icon icon="info-circle" />
                </div>
              </div>
              <div class="info-column">{{ info.faulty }}</div>
            </div>
            <div class="info">
              <div class="info-column">
                <span>ԿՍ</span>
                <div v-b-tooltip.hover.top="'Կրկնակի զննում անցած տրանսպորտային միջոցների քանակը'"  class="icon">
                  <fa-icon icon="info-circle" />
                </div>
              </div>
              <div class="info-column">{{ info.double }}</div>
            </div>
            <div class="info">
              <div class="info-column">
                <span>ԿՀ</span>
                <div v-b-tooltip.hover.top="'Կրկնվող հեռախոսահամարներ'"  class="icon">
                  <fa-icon icon="info-circle" />
                </div>
              </div>
              <div class="info-column">
                  <b-button size="sm" @click="openPhonesModal" variant="secondary">Ցանկ ({{ info.phones.length }})</b-button>
              </div>
            </div>
            <div class="info">
              <div class="info-column">
                <span>ՄԱ</span>
                <div v-b-tooltip.hover.top="'Մարզի ակտիվություն'"  class="icon">
                  <fa-icon icon="info-circle" />
                </div>
              </div>
              <div class="info-column">
                <b-button  size="sm" @click="openRegionsModal" variant="secondary">Ցանկ</b-button>
              </div>
            </div>
          </div>
        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

    <b-modal
        id="phones-info"
        :no-close-on-backdrop="true"
        centered
        size="lg"
        class="modal-responsive"
        title="Կրկնվող հեռախոսահամարներ"
        :is-valid="false"
    >

      <b-form v-if="info">
        <b-form-group>
          <div class="position-relative table-responsive">
            <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="perPagePhones"
                :current-page="currentPagePhones"
                :items="info.phones"
                :fields="phonesFields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
            >
            </b-table>
          </div>

          <b-form-group
              :label="'Գտնվել է ' + info.phones.length + ' տող'"
              label-cols="3"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
          >
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
                v-model="currentPagePhones"
                :total-rows="info.phones.length"
                :per-page="perPagePhones"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>

        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

    <b-modal
        id="regions-info"
        :no-close-on-backdrop="true"
        centered
        size="md"
        class="modal-responsive"
        title="Մարզի ակտիվություն"
        :is-valid="false"
    >

      <b-form v-if="info">
        <b-form-group>
          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table mb-2 b-table table-striped table-hover"
                   id="__BVID__140"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Մարզ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Քանակ</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->

              <tr v-for="region in info.regions" role="row" class="">
                <td aria-colindex="7" role="cell" class=""> {{ region.name }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ region.count }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>
  </div>
</template>

<script>

import AnalysisModule from '@/store/analysis'
import locations from '@/store/locations/locations'
import store from "@/store";
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BFormTextarea,
  BFormCheckbox,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'AnalysisData',
  components: {
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    BFormCheckbox,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    userType() {
      return atob(this.$store.state.user_type)
    },
    items () {
      return this.$store.state[this.MODULE_NAME].companies
    },
    addressName() {
      return (item) => {
        return item.region + ' ' + item.county + ' ' + item.street + ' ' + item.address
      }
    },
    totalRows() {
      return this.items.length
    },
    dateRange () {
      return this.$store.state[this.MODULE_NAME].dateRange
    },
    info () {
      return this.$store.state[this.MODULE_NAME].info
    },
    regions() {
      const result = []
      const data = this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].regions
      data.map((item) => {
        let temp = {
          id: item.name,
          label: item.name
        }
        result.push(temp)
      })
      return result
    },
    counties() {
      const result = []
      const data = this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].counties
      data.map((item) => {
        let temp = {
          id: item.name,
          label: item.name
        }
        result.push(temp)
      })
      return result
    },
  },
  data() {
    return {
      MODULE_NAME: 'analysisModule',
      filters: {
        region: null,
        county: null
      },
      perPage: 20,
      currentPage: 1,
      perPagePhones: 20,
      currentPagePhones: 1,
      fields: [
        {
          key: '_id',
          label: 'Հ/Հ'
        },
        {
          key: 'name',
          label: 'Անվանում',
        },
        {
          key: 'actions',
          label: 'Վերլուծություն'
        },
        {
          key: 'address',
          label: 'Հասցե',
        }
      ],
      phonesFields: [
        {
          key: 'region',
          label: 'Մարզ',
        },
        {
          key: 'CarRegNo',
          label: 'Համարանիշ'
        },
        {
          key: 'Phone',
          label: 'Հեռախոսահամար',
        }
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      LOCATIONS_STORE_MODULE_NAME: 'locationsModule',
    }
  },
  created() {
    if (!store.hasModule(this.MODULE_NAME)) store.registerModule(this.MODULE_NAME, AnalysisModule)
    if (!store.hasModule(this.LOCATIONS_STORE_MODULE_NAME)) store.registerModule(this.LOCATIONS_STORE_MODULE_NAME, locations)
    this.getCompanies()
    this.getRegions()
    this.getCounties()
  },
  methods: {
    getCompanies() {
      this.$vs.loading()
      const filterData = {
        ...this.filters
      }
      let query = this.serialize(filterData);
      this.$store.dispatch(`${this.MODULE_NAME}/getCompanies`, {
        type: this.userType,
        query: query
      }).then(() => {
        this.$vs.loading.close()
      }).catch(() => {
        this.$vs.loading.close()
      })
    },
    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getInfo (id) {
      this.$vs.loading()
      this.$store.dispatch(`${this.MODULE_NAME}/getInfo`, {
        type: this.userType,
        id: id,
        query: this.serialize(this.dateRange)
      }).then(() => {
        this.$vs.loading.close()
        this.$bvModal.show('modal-info')
      }).catch(() => {
        this.$vs.loading.close()
      })
    },
    openPhonesModal () {
      this.$bvModal.show('phones-info')
    },
    openRegionsModal () {
      this.$bvModal.show('regions-info')
    },
    filterRegion() {
      this.getCompanies()
      const data = this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].regions
      let regionId = null;
      let index = data.findIndex(e => e.name === this.filters.region)
      if (index !== -1) {
        regionId = data[index].id
      }
      if (regionId) {
        let filters = {
          region: regionId
        }
        this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/filterCounties', filters)
      }
    },
    filterCounty() {
      this.getCompanies()
    },
    getRegions() {
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getRegions')
    },
    getCounties() {
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getCounties')
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#companies {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 15rem);
    min-height: calc(var(--vh, 1vh) * 100 - 15rem);
  }
}
.infos_title {
  text-align: center;
  color: #000;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.infos {
  display: flex;
  width: 100%;
  flex-direction: column;
  .info {
    display: flex;
    width: 100%;
    border-top: 1px solid #ccc;
    .info-column {
      width: 50%;
      border-right: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      color: #000;
      font-weight: bold;
      font-size: 14px;
      &:first-child {
        border-left: 1px solid #ccc;
      }
      .icon {
        width: 20px;
        height: 20px;
        background: #00589e;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
        border-radius: 5px;
        margin: 0 10px;
      }
    }
    &:last-child {
      border-bottom: 1px solid #ccc;
    }
  }
}
</style>