<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="companies" no-body>

    <AnalysisData />

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import AnalysisData from './Data'

export default {
  name: 'analysis',
  components: { BCardCode, AnalysisData },
}
</script>
